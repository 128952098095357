import React from "react";
import { graphql } from "gatsby";
import { Section, Collapsible } from "../components/ui";
import Layout from "../components/layout";
import Seo from "../components/seo";

export default function Template({ data, location }) {
  const { markdownRemark } = data;
  const siteTitle = data.site.siteMetadata.title;
  const { frontmatter, html, tableOfContents } = markdownRemark;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={frontmatter.title} />
      <Section backgroundColor="bg-helfa-beige">
        <Collapsible title="Inhalt">
          <div
            className="text-left"
            dangerouslySetInnerHTML={{ __html: tableOfContents }}
          />
        </Collapsible>
      </Section>
      <Section backgroundColor="bg-helfa-beige">
        <div className="text-left">
          <div
            className="markdown-page"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      tableOfContents(
        absolute: false
        pathToSlugField: "frontmatter.slug"
        maxDepth: 2
      )
      frontmatter {
        slug
        title
        language
        category
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...I18n
        }
      }
    }
  }
`;
